.oneCard{
    display: flex;
    width: 13rem;
    height: 12rem;
    color: white;
    margin: 15px;
}
.valija{
    position: relative;
    top: 0;
    height: 12rem;
    width: 13.7rem;
    left: 0;
}
.name{   
    position: relative;
    text-align: center;
    top: -30px;
    height: 50px;
    font-size: 1.6vw;
}
.continent{    
    text-align: -webkit-center;
    position: relative;
    top: -40px;
    font-size: 1.3vw;
}

.img{    
    position: relative;
    height: 4rem;
    width: 8rem;
}
.information{
    position: relative;
    height: 137px;
    top: 3rem;
    left: -10.9rem;
}