.navBar{
    background-color: blue;
    margin-top: -21px;
    height: 5rem;
    position: relative;
    top: -38rem;
}
.filtrado{        
    display: flex;
    justify-content: space-evenly;
    position: relative;
    top: 30%; 
}