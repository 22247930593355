.total{
    display: flex;
}
.fondoNuevo{
    position: relative;
    width: 100rem;
    height: 39rem;
}
.form{
    position: absolute;
    top: 13rem;
    left: 23rem;
}
.activity{
    position: absolute;
    left: 20rem;
    height: 30px;
    width: 273px;
    top: 100%;
}
.activiyAlert{
    position: absolute;
    left:20rem;
    top:1.3rem;
    height: 30px;
    width: 250px;
    color: red;
}
.dificulty{
    position: absolute;
    height: 30px;
    width: 273px;
    left: 20rem;
    top: 7rem;
}
.duration{
    position: absolute;
    left: 20rem;
    height: 30px;
    width: 270px;
    top: 10rem;
}
.durationAlert{
    position: absolute;
    left:20rem;
    top: 11.5rem;
    height: 30px;
    width: 250px;
    color: red;
}
.season{
    position: absolute;
    left: 20rem;
    top: 14rem;
    height: 30px;
    width: 230px;
}
.seasonAlert{
    position: absolute;
    left:20rem;
    top: 15.5rem;
    height: 30px;
    width: 250px;
    color: red;
}
.countries{
    position: absolute;
    left: 2rem;
    top: -1.5rem;
    height: 30px;
    width: 500px;
}
.countriesAlert{
    position: absolute;
    left:2rem;
    height: 30px;
    width: 250px;
    color: red;
}
.enviar{
    position: absolute;
    height: 50px;
    width: 50px;
    top: 30rem;
    left: 38rem;

}
.barras{
    position: absolute;
    height: 44px;
    width: 128px;
    top: 4.5rem;
    left:24rem;
}
.volver{
    position: absolute;
    height: 74px;
    width: 116px;
    top: 30rem;
    left: 25rem;
}
.selected{
    position: absolute;
    width: 50px;
    left: 3rem;
    margin: 1px;
    height: 30px;
}
