.paginado{    
    position: relative;
    top: -7rem;
    color:white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 9px;
    z-index: 100;
}
.page{
    cursor: pointer;
    display: contents;
    
  }
.fondohome{
    position: relative;
    top:0rem;
    height: 100%;
    z-index: 0;
    width: 100%;
}
